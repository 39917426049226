import React, { useState, useEffect, useReducer } from "react";
import openSocket from "socket.io-client";

import {
    Button,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import BotMessageModal from "../../components/BotMessageModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
    if (action.type === "LOAD_BOT_MESSAGE") {
        const botMessages = action.payload;
        const newBotMessages = [];

        botMessages.forEach((botMessage) => {
            const botMessageIndex = state.findIndex((q) => q.id === botMessage.id);
            if (botMessageIndex !== -1) {
                state[botMessageIndex] = botMessage;
            } else {
                newBotMessages.push(botMessage);
            }
        });

        return [...state, ...newBotMessages];
    }

    if (action.type === "UPDATE_BOT_MESSAGE") {
        const botMessage = action.payload;
        const botMessageIndex = state.findIndex((q) => q.id === botMessage.id);

        if (botMessageIndex !== -1) {
            state[botMessageIndex] = botMessage;
            return [...state];
        } else {
            return [botMessage, ...state];
        }
    }

    if (action.type === "DELETE_BOT_MESSAGE") {
        const botMessageId = action.payload;

        const botMessageIndex = state.findIndex((q) => q.id === botMessageId);
        if (botMessageIndex !== -1) {
            state.splice(botMessageIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const BotMessages = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [botMessages, dispatch] = useReducer(reducer, []);
    const [selectedBotMessages, setSelectedBotMessages] = useState(null);
    const [BotMessageModalOpen, setBotMessageModalOpen] = useState(false);
    const [deletingBotMessages, setDeletingBotMessages] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchBotMessages = async () => {
                try {
                    const { data } = await api.get("/botMessages/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_BOT_MESSAGE", payload: data.data });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchBotMessages();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("botMessages", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_BOT_MESSAGE", payload: data.data });
            }

            if (data.action === "delete") {
                dispatch({
                    type: "DELETE_BOT_MESSAGE",
                    payload: +data.id,
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenBotMessagesModal = () => {
        setSelectedBotMessages(null);
        setBotMessageModalOpen(true);
    };

    const handleCloseBotMessagesModal = () => {
        setSelectedBotMessages(null);
        setBotMessageModalOpen(false);
    };

    const handleEditBotMessage = (botMessage) => {
        setSelectedBotMessages(botMessage);
        setBotMessageModalOpen(true);
    };

    const handleDeleteBotMessage = async (id) => {
        try {
            await api.delete(`/botMessages/${id}`);
            toast.success(i18n.t("botMessage.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingBotMessages(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingBotMessages &&
                    `${i18n.t("botMessage.confirmationModal.deleteTitle")} ${deletingBotMessages.key
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteBotMessage(deletingBotMessages.id)}
            >
                {i18n.t("botMessage.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <BotMessageModal
                open={BotMessageModalOpen}
                onClose={handleCloseBotMessagesModal}
                aria-labelledby="form-dialog-title"
                id={selectedBotMessages && selectedBotMessages.id}
            ></BotMessageModal>
            <MainHeader>
                <Title>{i18n.t("botMessage.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("botMessage.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenBotMessagesModal}
                    >
                        {i18n.t("botMessage.buttons.add")}
                    </Button> */}
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell align="center">
                                {i18n.t("botMessage.table.key")}
                            </TableCell> */}
                            <TableCell align="center">
                                {i18n.t("botMessage.table.description")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("botMessage.table.message")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("botMessage.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {botMessages.map((botMessage) => (
                                <TableRow key={botMessage.id}>
                                    {/*                                     <TableCell align="center">{botMessage.key}</TableCell>
 */}                                    <TableCell align="center">{botMessage.description}</TableCell>
                                    <TableCell align="center">{botMessage.message}</TableCell>

                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditBotMessage(botMessage)}
                                        >
                                            <Edit />
                                        </IconButton>

                                        {/*  <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingBotMessages(botMessage);
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default BotMessages;
