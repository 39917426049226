import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
	Tooltip,
	LineChart,
	Line,
} from "recharts";

import { i18n } from "../../translate/i18n";
import api from '../../services/api';

import Title from "./Title";

const ChartAlert = () => {
	const theme = useTheme();

	const [chartData, setChartData] = useState([
		{ time: "00:00", amount: 0 },
		{ time: "01:00", amount: 0 },
		{ time: "02:00", amount: 0 },
		{ time: "03:00", amount: 0 },
		{ time: "04:00", amount: 0 },
		{ time: "05:00", amount: 0 },
		{ time: "06:00", amount: 0 },
		{ time: "07:00", amount: 0 },
		{ time: "08:00", amount: 0 },
		{ time: "09:00", amount: 0 },
		{ time: "10:00", amount: 0 },
		{ time: "11:00", amount: 0 },
		{ time: "12:00", amount: 0 },
		{ time: "13:00", amount: 0 },
		{ time: "14:00", amount: 0 },
		{ time: "15:00", amount: 0 },
		{ time: "16:00", amount: 0 },
		{ time: "17:00", amount: 0 },
		{ time: "18:00", amount: 0 },
		{ time: "19:00", amount: 0 },
		{ time: "20:00", amount: 0 },
		{ time: "21:00", amount: 0 },
		{ time: "22:00", amount: 0 },
		{ time: "23:00", amount: 0 },

	]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get('alerts/reports/today');
				setChartData(data);

			} catch (err) {
				///toastError(err);
			}
		};
		fetchSession();
	}, []);

	return (
		<React.Fragment>
			<Title>Alertas últimas 24 horas</Title>
			<ResponsiveContainer>

				<LineChart data={chartData}
					//barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}} >
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Alertas
						</Label>
					</YAxis>
					<CartesianGrid stroke="#eee" strokeDasharray="5 5" />
					<Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} />
					<Tooltip />

				</LineChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default ChartAlert;
