import React, { useState, useEffect, useReducer } from "react";
import openSocket from "socket.io-client";

import {

    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    InputAdornment,
    TextField,
    Select,
    MenuItem,
    Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
    if (action.type === "LOAD_LOGS") {
        const logs = action.payload || [];
        const newLogs = [];

        logs.forEach((log) => {
            const logIndex = state.findIndex((q) => q.id === log.id);
            if (logIndex !== -1) {
                state[logIndex] = log;
            } else {
                newLogs.push(log);
            }
        });

        return [...state, ...newLogs];
    }

    if (action.type === "UPDATE_LOGS") {
        const logs = action.payload || [];
        const logIndex = state.findIndex((q) => q.id === logs.id);

        if (logIndex !== -1) {
            state[logIndex] = logs;
            return [...state];
        } else {
            return [logs, ...state];
        }
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Logs = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [searchLevel, setSearchLevel] = useState("");

    const [logs, dispatch] = useReducer(reducer, []);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
        //   setSearchLevel("");
    }, [searchParam, searchLevel]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchBotMessages = async () => {
                try {
                    const { data } = await api.get("/logs", {
                        params: { searchParam, pageNumber, limit: 20, level: searchLevel },
                    });
                    dispatch({ type: "LOAD_LOGS", payload: data.data });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchBotMessages();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, searchLevel]);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("logs", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_LOGS", payload: data.data });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>

            <MainHeader>
                <Title>Logs</Title>
                <MainHeaderButtonsWrapper>

                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenBotMessagesModal}
                    >
                        {i18n.t("botMessage.buttons.add")}
                    </Button> */}
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell align="center">
                                {i18n.t("botMessage.table.key")}
                            </TableCell> */}
                            <TableCell align="center">
                                Level

                            </TableCell>
                            <TableCell align="center">
                                LOG
                            </TableCell>
                            <TableCell align="center">
                                Data
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Select
                                    value={searchLevel}
                                    onChange={(e) => {
                                        setSearchLevel(e.target.value);

                                    }}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value="info">Info</MenuItem>
                                    <MenuItem value="error">Erro</MenuItem>
                                    <MenuItem value="warn">Aviso</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    placeholder="Pesquisar log"
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setSearchParam("");
                                        setSearchLevel("");
                                    }}
                                >
                                    Limpar
                                </Button>
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        <>
                            {logs.map((log) => (
                                <TableRow key={log.id}>
                                    <TableCell align="center">{log.level}</TableCell>
                                    <TableCell align="center">{log.message}</TableCell>

                                    <TableCell align="center">
                                        {new Date(log.createdAt).toLocaleString()}
                                    </TableCell>


                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Logs;
